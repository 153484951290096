import React, { useEffect } from "react";

import { BrowserRouter, useParams, useSearchParams } from "react-router-dom";

import {
  Routes,
  Route,
  Link
} from "react-router-dom";

import { CookiesProvider, useCookies } from 'react-cookie';
import { Outlet } from "react-router-dom";

import SignatureApp from './SignatureApp'

import {LinkSetup} from './Setup'
import { Avatar, Box, Button, Container, createTheme, CssBaseline, ThemeProvider, Typography } from "@mui/material";

import { createBrowserHistory } from 'history'

const replaceHashPath = () => {
  const history = createBrowserHistory()
  const hash = history.location.hash
  if (hash) {
    const path = hash.replace(/^#/, '')
    if (path) {
      history.replace(path)
    }
  }
}
replaceHashPath()

const App = () => {

  return (
    <CookiesProvider>
      <Environment />
    </CookiesProvider>
  );
}

export default App;

const Layout = () => {

  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </ThemeProvider>
  )
}

const Environment = () => {

  const [cookies] = useCookies(['station']);

  const posConfig = {
    type: 'checkout',
    connection: {
      station: cookies.station,
    }
  }

  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<SignatureApp posConfig={posConfig} />} />
            <Route path="setup/*" element={<LinkSetup />} />
            <Route path="setup/use/:link" element={<LinkSetup />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}




